<template>
  <div class="mustBuyList flex fd-c ai-c"  v-if="list.length>0">
      <div class="mustBuy-banner" @click="$router.push({path: '/home/mustBuyList',query: {
          picture: picture
        }})">
        <img :src="picture" alt="">
      </div>
      <div class="list flex fd-c ai-c">
          <template v-for="(item, index) in list">
              <v-mustBuyGoods :goods="item" :key="index" />
          </template>
      </div>
      <div class="seeAll" @click="$router.push({path: '/home/mustBuyList',query: {
          picture: picture
        }})">查看全部></div>
  </div>
</template>

<script>
import mustBuyGoods from '@/components/mustBuyGoods'
export default {
  components:{
      'v-mustBuyGoods': mustBuyGoods
  },
  props:["list",'picture'],
  data(){
    return {
    }
  },
  watch:{},
  computed:{},
  methods:{},
  created(){},
  mounted(){}
}
</script>
<style scoped>
.mustBuyList{
    width: 100%;
    margin-top: .4rem;
}
.mustBuy-banner{
    width: 100%;
    height: 1.38rem;
    /* background-image: url(/img/mustBuy-big.png);
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center; */
    
}
.mustBuy-banner img{
    width: 100%;
    height: 100%;
}
.list{
    width: 100%;
    margin-top: .3rem;
}
.seeAll{
    width: 7.12rem;
    line-height: .85rem;
    text-align: center;
    background: #FFFFFF;
    border-radius: .04rem .04rem .14rem .14rem;
    font-size: .28rem;
    font-family: PingFang;
    font-weight: 500;
    color: #282828;
}

</style>