<template>
  <div class="newPeople flex jc-c">
      <div class="box">
          <img class="huangguan" src="/img/huangguan.png" alt="">
          <div class="list-box" id="listBox">
              <div class="list flex" id="list">
                <div class="redEnvelopes flex fd-c ai-c">
                    <div class="title">专属礼包</div>
                    <div class="nb">100</div>
                    <div class="sm flex ai-c jc-c">-全场通用-</div>
                    <div class="btn"></div>
                </div>
                <div class="item flex fd-c ai-c" v-for="(item, index) in 2" :key="index">
                    <img class="icon" src="/img/icon-nwePeople.png" alt="">
                    <img class="goods-img" src="/img/goods.png" alt="">
                    <div class="name lines">LANCÔME 兰蔻 臻白晚霜 15毫 ...</div>
                    <div class="btn flex ai-c jc-c">¥0.1</div>
                </div>
              </div>
          </div>
          <div class="indicator-box flex jc-c" style="margin-top:.15rem">
            <v-indicator wId="listBox" nId="list" :innerBarStyle="innerBarStyle"  />
        </div>
      </div>
  </div>
</template>

<script>
import indicator from '@/components/indicator'
export default {
  components:{
      'v-indicator': indicator,
  },
  props:{},
  data(){
    return {
        innerBarStyle: {
            wBgc: 'rgba(193, 33, 11, 1)',
            nBgc: '#fff'
        }
    }
  },
  watch:{},
  computed:{},
  methods:{},
  created(){},
  mounted(){}
}
</script>
<style scoped>
.newPeople{
    width: 100%;
    margin-top: .38rem;
}
.box{
    position: relative;
    width: 7.1rem;
    height: 4.8rem;
    background-image: url(/img/newPeople-bg.png);
    background-size: 100% 100%; 
    background-repeat: no-repeat;
}
.huangguan{
    position: absolute;
    right: .1rem;
    top: -.3rem;
    width: 2rem;
}

.list-box {
    width: calc(100% - .4rem);
    box-sizing: border-box;
    margin-top: .85rem;
    margin-left: .2rem;
    overflow-x: scroll;
}
.list-box::-webkit-scrollbar{
    height: 0;
    display: none;/*隐藏滚动条*/
}
.list{
    width: min-content;
}
.list .redEnvelopes{
    width: 2.18rem;
    min-width: 2.18rem;
    height: 3.54rem;
    margin-right: .14rem;
    background-image: url(/img/redEnvelopes.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.list .redEnvelopes .title{
    font-size: .32rem;
    font-family: PingFang;
    font-weight: bold;
    color: #BC7523;
    margin-top: .5rem;
}
.list .redEnvelopes .nb{
    font-size: .55rem;
    font-family: PingFang;
    font-weight: 800;
    color: #FF3604;
    margin-left: .25rem;
    margin-top: .05rem;
}
.list .redEnvelopes .nb::before{
    content: '¥';
    font-size: .25rem;
    font-family: PingFang;
    font-weight: bold;
    color: #E20505;
    background: linear-gradient(0deg, #FE5959 0%, #E20505 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.list .redEnvelopes .sm{
    height: .44rem;
    background: #FFEAAD;
    border: 2px solid #FFD45F;
    border-radius: .08rem;
    font-size: .20rem;
    font-family: PingFang;
    font-weight: bold;
    color: #AF7610;
    padding: 0 .15rem;
}
.list .redEnvelopes .btn{
    width: 1.7rem;
    height: 0.6rem;
    margin-top: .6rem;
}
.list .item{
    position: relative;
    width: 2.14rem;
    min-width: 2.14rem;
    height: 3.54rem;
    background: #FFFFFF;
    border-radius: .14rem;
    margin-right: .14rem;
}
.list .item .icon{
    position: absolute;
    top: .06rem;
    right: .1rem;
    width: .64rem;
    height: .58rem;
}
.list .item .goods-img{
    width: 1.8rem;
    height: 1.8rem;
    margin-top: .1rem;
}
.list .item .name{
    width: 1.8rem;
    font-size: .24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #3B3B3B;
    line-height: .30rem;
    margin-top: .15rem;
}
.list .item .btn{
    width: 1.76rem;
    height: .54rem;
    background: #F96F30;
    border-radius: .27rem;
    font-size: .36rem;
    font-family: PingFang;
    font-weight: 500;
    color: #FFFFFF;
    margin-top: .1rem;
}
</style>