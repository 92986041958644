<template>
  <div class="hot-nav-bottom" v-show="isShow" :style="{background: innerBarStyle&&innerBarStyle.wBgc}">
    <div class="hot-nav-bottom-inner" :style="{left: Left+'%',background: innerBarStyle&&innerBarStyle.nBgc}"></div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["wId","nId","innerBarStyle"],
  data() {
    return {
      Left: 0,
      isShow: true
    };
  },
  watch: {},
  computed: {
    
  },
  methods: {
  },
  created() {},
  mounted() {
    this.$nextTick(()=>{
        var dom = document.getElementById(this.wId)
        var wWidth = dom.offsetWidth
        var nWidth = document.getElementById(this.nId).offsetWidth
        this.isShow = nWidth<=wWidth?false:true
        dom.onscroll = (e)=>{
          var Left = dom.scrollLeft/(nWidth-wWidth) * 50
          Left = Left<0?0:Left
          Left = Left>50?50:Left
          this.Left = Left
        }
    })
  },
};
</script>
<style scoped>

.hot-nav-bottom {
  position: relative;
  width: .6rem;
  height: .06rem;
  background: #DDDDDD;
  border-radius: .03rem;
}
.hot-nav-bottom .hot-nav-bottom-inner {
  position: absolute;
  left: 0;
  width: .3rem;
  height: .06rem;
  background: #FE5625;
  border-radius: .03rem;
}
</style>