<template>
  <div class="mustBuyList flex fd-c ai-c">
      <div class="mustBuy-banner">
        <img :src="picture" alt="">
      </div>
      <v-loveGoodsList :isHide="true" />
  </div>
</template>

<script>
import loveGoodsList from '@/components/loveGoodsList'
export default {
  components:{
      'v-loveGoodsList': loveGoodsList
  },
  props:['picture'],
  data(){
    return {
    }
  },
  watch:{},
  computed:{},
  methods:{},
  created(){},
  mounted(){}
}
</script>
<style scoped>
.mustBuyList{
    width: 100%;
    margin-top: .4rem;
}
.mustBuy-banner{
    width: 100%;
    height: 1.38rem;
    /* background-image: url(/img/loveGoods-big.png); */
    /* background-size: 100% 100%; */
    /* background-repeat: no-repeat; */
    margin-bottom: .3rem;
}
.mustBuy-banner img{
  width: 100%;
  height: 100%;
}
.list{
    width: 100%;
    margin-top: .3rem;
}
.seeAll{
    width: 7.12rem;
    line-height: .85rem;
    text-align: center;
    background: #FFFFFF;
    border-radius: .04rem .04rem .14rem .14rem;
    font-size: .28rem;
    font-family: PingFang;
    font-weight: 500;
    color: #282828;
}
</style>