<template>
  <div class="rankingGoods" @click="$router.push('/home/localLifeDetails')">
      <template v-if="!isNumberHide">
        <div v-if="goods==0" class="nb ranking1">{{goods+1}}</div>
        <div v-if="goods==1" class="nb ranking2">{{goods+1}}</div>
        <div v-if="goods==2" class="nb ranking3">{{goods+1}}</div>
        <div v-if="goods>=3" class="nb ranking4">{{goods+1}}</div>
      </template>
      <div class="ranking-t flex">
          <img class="img-Big" src="/img/goods.png" alt="">
          <div class="nr">
              <div class="name line">卜·寿喜烧</div>
              <div class="lb line">南湖公园美食热门榜第4名</div>
              <div class="starList flex ai-c">
                  <div class="txt">环境<i></i></div>
                  <div class="mao" style="line-height: .26rem">：</div>
                  <template v-for="(item, index) in 1">
                      <van-icon :key="index+'star'" size=".29rem" name="star" />
                  </template>
                  <template v-for="(item, index) in 4">
                      <van-icon :key="index" size=".29rem" name="star-o" />
                  </template>
              </div>
              <div class="starList flex ai-c">
                  <div class="txt">性价比<i></i></div>
                  <div class="mao" style="line-height: .26rem">：</div>
                  <template v-for="(item, index) in 1">
                      <van-icon :key="index+'star'" size=".29rem" name="star" />
                  </template>
                  <template v-for="(item, index) in 4">
                      <van-icon :key="index" size=".29rem" name="star-o" />
                  </template>
              </div>
              <div class="starList flex ai-c">
                  <div class="txt">服务<i></i></div>
                  <div class="mao" style="line-height: .26rem">：</div>
                  <template v-for="(item, index) in 1">
                      <van-icon :key="index+'star'" size=".29rem" name="star" />
                  </template>
                  <template v-for="(item, index) in 4">
                      <van-icon :key="index" size=".29rem" name="star-o" />
                  </template>
              </div>
              <div class="money-box flex ai-bl">
                  <div class="money1">128</div>
                  <div class="money2">¥216</div>
              </div>
          </div>
      </div>
      <div class="ranking-b flex jc-sb ai-c">
          <div class="biaoqian">3个月内历史最低价</div>
          <div class="btn">立即抢购</div>
      </div>
  </div>
</template>

<script>
export default {
  components:{},
  props:["goods","isNumberHide"],
  data(){
    return {
    }
  },
  watch:{},
  computed:{},
  methods:{},
  created(){},
  mounted(){}
}
</script>
<style scoped>
.rankingGoods{
    position: relative;
    width: 7.10rem;
    height: 4.14rem;
    background: #FFFFFF;
    border-radius: .14rem;
    overflow: hidden;
    margin-bottom: .25rem;
}

.img-Big{
    width: 2.94rem;
    height: 2.94rem;
    border-radius: .10rem;
    margin-left: .1rem;
}
.ranking-t{
    margin-top: .26rem;
}
.nr{
    width: 3.36rem;
    margin-left: .4rem;
}
.nr .name{
    font-size: .36rem;
    font-family: PingFang;
    font-weight: 800;
    color: #282828;
}
.nr .lb{
    font-size: .24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #6E6E6E;
}
.nr .txt{
    position: relative;
    width: .8rem;
    text-align: justify;
    font-size: .24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #282828;
    line-height: .26rem;
    height: .26rem;
}
.txt i{
  display:inline-block;
  /*padding-left: 100%;*/
  width:100%;
}
.starList{
    margin-top: .05rem;
}
.starList>>>.van-icon-star-o{
    color: rgba(249, 111, 48, 1);
    line-height: .3rem;
}
.starList>>>.van-icon-star{
    color: rgba(249, 111, 48, 1);
    line-height: .3rem;
}
.money-box{
    margin-top: .2rem;
}
.money-box .money1{
    font-size: .52rem;
    font-family: PingFang;
    font-weight: bold;
    color: #FF3604;
}
.money-box .money1::before{
    content: '¥';
    font-size: .38rem;
}
.money-box .money2{
    font-size: .26rem;
    font-family: PingFang;
    font-weight: 500;
    color: #6E6E6E;
    margin-left: .25rem;
    text-decoration:line-through;
}

.ranking-b .biaoqian{
    padding: 0 .35rem 0 .13rem;
    height: .44rem;
    background: #F4F4F4;
    border-radius: 0rem .22rem .22rem 0rem;
    font-size: .22rem;
    font-family: PingFang;
    font-weight: 500;
    color: #6E6E6E;
    margin-left: .1rem;
    line-height: .44rem;
}
.ranking-b .btn{
    width: 3.36rem;
    height: .64rem;
    line-height: .64rem;
    text-align: center;
    background: #F96F30;
    border-radius: .32rem;
    margin-right: .3rem;
    font-size: .36rem;
    font-family: PingFang;
    font-weight: 500;
    color: #FFFFFF;
}
.nb{
    position: absolute;
    left: .1rem;
    top: .1rem;
    width: .50rem;
    height: .74rem;
    text-align: center;
    line-height: .8rem;
    background-size: 100% 100%;
    font-size: .33rem;
    font-family: PingFang;
    font-weight: 800;
    color: #FFFFFF;
    text-shadow: 0rem .04rem .13rem rgba(168, 110, 85, 0.41);
}
.ranking1{
    background-image: url(/img/icon-ranking1.png);
}
.ranking2{
    background-image: url(/img/icon-ranking2.png);
}
.ranking3{
    background-image: url(/img/icon-ranking3.png);
}
.ranking4{
    background-image: url(/img/icon-ranking4.png);
}
</style>