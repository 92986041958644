<template>
  <div class="preferential flex fd-c ai-c" v-if="list.length>0">
      <div class="preferential-banner" @click="Click">
        <img :src="picture" alt="">
      </div>
      <div class="list">
          <div class="flex fw-w">
              <div class="preferential-item" v-for="(item, index) in list" :key="index">
                  <v-loveGoods :goods="item" />
              </div>
          </div>
      </div>
      <div class="seeAll" @click="Click">查看全部></div>
  </div>
</template>

<script>
import loveGoodsList from '@/components/loveGoodsList'
import loveGoods from '@/components/loveGoods'
import { goodsTag } from '../../api'
export default {
  components:{
      'v-loveGoodsList': loveGoodsList,
      'v-loveGoods': loveGoods
  },
  props:['picture'],
  data(){
    return {
        current: 1,
        list: []
    }
  },
  watch:{},
  computed:{},
  methods:{
    goodsTag() {
      goodsTag({
        goodsTagId: 12,
        current: this.current,
        size: 6,
      }).then(res=>{
        var list = res.data.data.records
        // 加载状态结束
        this.loading = false;
        this.current++
        if (list.length==0) {
          this.finished = true;
        }
        for (var item of list) {
          item.pic = item.pic?item.pic.split(',')[0]:[]
          this.list.push(item)
        }
      })
    },
    Click() {
        this.$router.push({path: '/home/preferentialList',query: {
          picture: this.picture
        }})
    }
  },
  created(){
      this.goodsTag()
  },
  mounted(){}
}
</script>
<style scoped>
.preferential{
    width: 100%;
    margin-top: .2rem;
}
.preferential-banner{
    width: 100%;
    height: 1.37rem;
    /* background-image: url(/img/preferential-min.png);
    background-size: 100% 100%;
    background-repeat: no-repeat; */
}
.preferential-banner img{
    width: 100%;
    height: 100%;
}
.list{
    width: 100%;
    margin-top: .3rem;
}
.seeAll{
    width: 7.12rem;
    line-height: .85rem;
    text-align: center;
    background: #FFFFFF;
    border-radius: .04rem .04rem .14rem .14rem;
    font-size: .28rem;
    font-family: PingFang;
    font-weight: 500;
    color: #282828;
}
.preferential-item{
    margin-left: .233rem;
}
</style>