<template>
  <div class="rankingList flex fd-c ai-c">
      <div class="ranking-banner" @click="$router.push('/home/rankingList')"></div>
      <div class="nav flex ai-c">
          <div class="item" 
          v-for="(item, index) in nav" 
          :class="active==index&&'active'"
          :key="index">
            {{item.name}}
          </div>
      </div>
      <div class="list flex fd-c ai-c">
          <template v-for="(item, index) in 6">
              <v-rankingGoods :goods="index" :key="index" />
          </template>
          
      </div>
      <div class="seeAll" @click="$router.push('/home/rankingList')">查看全部></div>
  </div>
</template>

<script>
import rankingGoods from '@/components/rankingGoods'
export default {
  components:{
      'v-rankingGoods': rankingGoods
  },
  props:{},
  data(){
    return {
        nav: [{
            name: '美食小吃'
        },{
            name: '新晋良店'
        },{
            name: '咖啡茶品'
        },{
            name: '周末去哪玩'
        },{
            name: '亲子玩乐'
        }],
        active: 0
    }
  },
  watch:{},
  computed:{},
  methods:{},
  created(){},
  mounted(){}
}
</script>
<style scoped>
.rankingList{
    width: 100%;
    margin-top: .4rem;
}
.ranking-banner{
    width: 100%;
    height: 1.7rem;
    background-image: url(/img/ranking-Big.png);
    background-size: auto 100%;
    background-repeat: no-repeat;
}
.rankingList .nav{
    width: 100%;
    overflow-x: scroll;
    padding-left: .2rem;
    box-sizing: border-box;
    margin-top: .2rem;
}
.rankingList .nav .item{
    white-space: nowrap;
    line-height: .52rem;
    padding: 0 .2rem;
    height: .52rem;
    border-radius: .26rem;
    font-size: .28rem;
    font-family: PingFang;
    font-weight: bold;
    color: #282828;
    margin-right: .2rem;
}
.rankingList .nav .active{
    background: #64B8CD;
    font-size: .32rem;
    font-weight: 800;
    color: #FFFFFF;
}
.list{
    width: 100%;
    margin-top: .3rem;
}
.seeAll{
    width: 7.12rem;
    line-height: .85rem;
    text-align: center;
    background: #FFFFFF;
    border-radius: .04rem .04rem .14rem .14rem;
    font-size: .28rem;
    font-family: PingFang;
    font-weight: 500;
    color: #282828;
}
</style>