<template>
  <div class="mobileChannelList flex fd-c ai-c">
      <div class="mobileChannel-banner"></div>
      <div class="list flex fw-w ai-c jc-sb">
          <template v-for="(item, index) in list">
              <div v-if="item.type==1" :key="index" class="item1">
                  <img :src="item.img" alt="">
              </div>
              <div v-if="item.type==2" :key="index" class="item2">
                  <img :src="item.img" alt="">
              </div>
          </template>
          
      </div>
  </div>
</template>

<script>
export default {
  components:{
  },
  props:{},
  data(){
    return {
        list: [{
            img:'/img/yd1.png',
            type: 1
        },{
            img:'/img/yd2.png',
            type: 1
        },{
            img:'/img/yd3.png',
            type: 2
        },{
            img:'/img/yd4.png',
            type: 2
        },{
            img:'/img/yd5.png',
            type: 2
        },{
            img:'/img/yd6.png',
            type: 2
        }]
    }
  },
  watch:{},
  computed:{},
  methods:{},
  created(){},
  mounted(){}
}
</script>
<style scoped>
.mobileChannelList{
    width: 100%;
    margin-top: .4rem;
}
.mobileChannel-banner{
    position: relative;
    z-index: 10;
    width: 7.3rem;
    height: 2.1rem;
    background-image: url(/img/yidong-big.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
}
.list{
    width: 7.1rem;
    padding-top: .3rem;
    background: #4727AE;
    border: .04rem solid #8256EB;
    border-radius: .10rem;
    margin-top: -.3rem;
    padding-bottom: .2rem;
}
.list .item1{
    width: 6.9rem;
    margin-left: .1rem;
    padding-top: .25rem;
    padding-bottom: .1rem;
    /* border-bottom: 1px dashed rgba(232, 226, 254, 1); */
}
.list .item1 img{
    width: 100%;
}
.list .item2{
    width: 2.96rem;
    padding-left: .25rem;
    margin-right: .25rem;
    padding-top: .25rem;
    padding-bottom: .1rem;
    /* border-bottom: 1px dashed rgba(232, 226, 254, 1); */
}
.list .item2 img{
    width: 100%;
}
</style>