<template>
  <div class="find">
      <img class="title" src="/img/find-t.png" alt="">
      <div class="slide-box" id="slide-box">
          <div class="slide flex" id="slide">
              <div class="slide-item">
                  <img src="/img/slide-img.png" alt="">
              </div>
              <div class="slide-item">
                  <img src="/img/slide-img.png" alt="">
              </div>
          </div>
      </div>
      <div class="indicator flex jc-c" style="margin-top:.2rem">
          <v-indicator wId="slide-box" nId="slide" :innerBarStyle="innerBarStyle" />
      </div>
  </div>
</template>

<script>
import indicator from '@/components/indicator'
export default {
  name: 'find',
  components:{
      'v-indicator': indicator,
  },
  props:{},
  data(){
    return {
        innerBarStyle: {
            wBgc: 'rgba(249, 111, 48, 1)',
            nBgc: 'rgba(193, 33, 11, 1)'
        }
    }
  },
  watch:{},
  computed:{},
  methods:{},
  created(){},
  mounted(){}
}
</script>
<style scoped>
.find{
    width: 7.10rem;
    margin-left: .2rem;
    height: 4.52rem;
    background-color: #FFFFFF;
    border-radius: .14rem;
    margin-top: .25rem;
    background-image: url(/img/find-bg.png);
    background-size: 100% auto;
    background-position-y: 60%;
    background-repeat: no-repeat;
}
.find .title{
    height: .41rem;
    margin-left: .23rem;
    margin-top: .2rem;
}
.slide-box{
    width: 100%;
    height: 3.25rem;
    overflow-x: scroll;
    margin-top: .1rem;
}
.slide{
    width: min-content;
    height: 100%;
}
.slide-item{
    width: 5.45rem;
    height: 100%;
    margin: 0 .1rem;
    border-radius: .14rem;
    border: .04rem solid #FFFFFF;
    box-sizing: border-box;
    overflow: hidden;
}
.slide-item img{
    width: 100%;
    height: 100%;
}
</style>