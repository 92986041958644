<template>
    <div class="home">

        <img class="home-title" src="/img/home-title.png" alt="">
        <!-- <div class="home-title" style="margin-top:0;width: auto;">南平市商业集团采购网上超市</div> -->
        <div class="search-box flex ai-c">
            <img class="icon-itt" src="/img/icon-itt.png" alt="">
            <!-- <img style="width:.71rem;margin-left:0" class="icon-itt" src="/img/jituan.jpg" alt=""> -->
            <div class="search flex ai-c" @click="searchClick">
                <img src="/img/icon-search.png" alt="">
                搜索商品
            </div>
        </div>
        <div @click="$router.push({ path: '/couponCenter' })" class="icon-receivingCenter"></div>
        <!-- <div class="hot-search flex">
          <div class="lb">热搜：</div>
          <div class="hot-list flex ai-c">
              <div class="item" @click="$router.push({path:'/home/search',query:{keywords: item}})" v-for="(item, index) in hotSearch" :key="index">{{item}}</div>
          </div>
      </div> -->
        <!-- <template v-for="(item, index) in banner">
            <img class="banner-bg" v-show="current == index" :key="index" :src="item.backPic" alt="">
        </template> -->
        <img class="banner-bg" src="/img/home-bg.png" alt="" />
        <van-swipe class="my-swipe" @change="onChange" :autoplay="8000" indicator-color="rgba(81, 81, 81, 1)">
            <van-swipe-item v-for="(item, index) in banner" @click="bannerClick(item)" :key="index">
                <img :src="item.pic" alt="">
            </van-swipe-item>
        </van-swipe>
        <div id="categoryBox" class="category-box">
            <div class="category-list flex" id="categoryList">
                <div class="category-item flex fd-c ai-c" v-for="(item, index) in categoryList"
                    @click="categoryClick(item)" :key="index">
                    <img :src="item.icon" alt="">
                    <div class="txt">{{ item.name }}</div>
                </div>
            </div>
        </div>

        <div class="indicator-box flex jc-c" v-if="categoryList.length > 0">
            <v-indicator wId="categoryBox" nId="categoryList" />
        </div>
        <van-tabs style="margin-top:0rem" v-model="tabActive" scrollspy sticky class="modular-nav">
            <van-tab v-for="(item, index) in modularNav" :key="index">
                <template #title>
                    <div class="modular-item" :class="(tabActive != index) || 'active'">
                        {{ item.name }}
                    </div>
                </template>
                <!-- <template v-if="index==0">
                    <div class="ad">
                        <img src="/img/ad.png" alt="">
                    </div>
                    新人
                    <v-newPeople />
                    新人
                    榜单推荐
                    <v-ranking />
                    榜单推荐
                    发现好货
                    <v-find />
                    发现好货
                </template> -->
                <!-- <template v-if="index==0"> -->
                <!-- 超值拼团 -->
                <!-- <v-group :list="groupList" /> -->
                <!-- 超值拼团 -->
                <!-- 移动频道 -->
                <!-- <v-mobileChannel /> -->
                <!-- 移动频道 -->
                <!-- </template> -->
                <template v-if="index == 0">
                    <!-- 今日必买 -->
                    <v-mustBuy :list="mustBuyList" :picture="item.cover" />
                    <!-- 今日必买 -->
                </template>

                <template v-if="index == 1">
                    <!-- 9.9特惠 -->
                    <v-preferential :picture="item.cover" />
                    <!-- 9.9特惠 -->
                </template>

                <template v-if="index == 2">
                    <!-- 精品推荐 -->
                    <v-love :picture="item.cover" />
                    <!-- 精品推荐 -->
                </template>
            </van-tab>
        </van-tabs>

        <v-tabbar :active="0" />
    </div>
</template>

<script>
import indicator from '@/components/indicator'
import tabbar from '@/components/tabbar'

import newPeople from './newPeople'
import ranking from './ranking'
import find from './find'
import group from './group'
import mobileChannel from './mobileChannel'
import preferential from './preferential'
import mustBuy from './mustBuy'
import love from './love'
import { marketPage, advertiseList, goodsTag, category, getSpecialList } from '../../api'
export default {
    name: 'home',
    components: {
        'v-indicator': indicator,
        'v-newPeople': newPeople,
        'v-ranking': ranking,
        'v-find': find,
        'v-group': group,
        'v-mobileChannel': mobileChannel,
        'v-preferential': preferential,
        'v-mustBuy': mustBuy,
        'v-love': love,
        'v-tabbar': tabbar,
    },
    props: {},
    data() {
        return {
            banner: [],
            current: 0,
            hotSearch: ['小黄人旅行箱', '品质家电', '抑菌口喷', '蛋黄酥', '夏日活力好物'],
            categoryList: [],
            modularNav: [],
            modularActive: 0,
            fixed: false,
            opacity: 0,
            mustBuyList: [],
            groupList: [],
            tabActive: 0,
            timer: null
        }
    },
    watch: {},
    computed: {},
    activated() {
        // var scrollTop = localStorage.getItem('homeScrollTop')
        // document.body.scrollTop = document.documentElement.scrollTop = scrollTop
    },
    methods: {
        //类别
        getSpecialList() {
            getSpecialList({}).then(res => {
                this.modularNav = res.data.data
            })
        },
        //搜索点击
        searchClick() {
            localStorage.removeItem('searchRefresh')
            this.$router.push('/home/search')
        },
        //类别点击
        categoryClick(item) {
            localStorage.removeItem('productListRefresh')
            this.$router.push({ path: '/home/productList', query: { id: item.id } })
        },
        //轮播图onChang
        onChange(index) {
            this.current = index;
        },
        //轮播图点击
        bannerClick(item) {
            if (item.jumpType == 1) {
                var arr = item.url.split('?')
                var query = {}
                if (arr.length > 1) {
                    var strArr = arr[1].split('&')
                    for (var item of strArr) {
                        query[item.split("=")[0]] = item.split("=")[1]
                    }
                }
                this.$router.push({ path: arr[0], query: query })
            } else if ((item.jumpType == 2)) {
                //   window.open(item.url)
                window.location.href = item.url
            }
        },
        fixedNav() {
            var offsetTop = this.$refs.nav.offsetTop
            var scrollTop = document.documentElement.scrollTop || window.pageYOfset || document.body.scrollTop;
            this.opacity = (scrollTop - offsetTop) / 40
            scrollTop >= offsetTop ? this.fixed = true : this.fixed = false
        },
        //顶部轮播图
        advertiseList() {
            advertiseList({
                type: 0
            }).then(res => {
                this.banner = res.data.data
            })
        },
        //商品类别
        category() {
            category({}).then(res => {
                this.categoryList = res.data.data
            })
        },
        //拼团列表
        marketPage() {
            marketPage({
                status: 1,
                current: 1,
                size: 3,
            }).then(res => {
                var list = res.data.data.records
                for (var item of list) {
                    item.date = this.countDownTime2(item.endTime.replace(/-/g, '/'), 'day', this.currentTime())
                }
                this.groupList = list
                this.timer = setInterval(() => {
                    for (var item of list) {
                        item.date = this.countDownTime2(item.endTime.replace(/-/g, '/'), 'day', this.currentTime())
                    }
                    //  console.log(this.groupList);
                }, 1000)

            })
        },
        //今日必买
        goodsTag() {
            goodsTag({
                goodsTagId: 1,
                current: 1,
                size: 6,
            }).then(res => {
                var list = res.data.data.records
                for (var item of list) {
                    item.pic = item.pic ? item.pic.split(',')[0] : []
                    this.mustBuyList.push(item)
                }
            })
        },
        currentTime() {
            function nb(data) {
                return data < 10 ? '0' + data : data
            }
            var now = new Date();
            var year = now.getFullYear(); //得到年份
            var month = now.getMonth() + 1;//得到月份
            var date = now.getDate();//得到日期
            // var day = now.getDay();//得到周几
            var hour = now.getHours();//得到小时数
            var minute = now.getMinutes();//得到分钟数
            var second = now.getSeconds();//得到秒数
            return year + '/' + nb(month) + '/' + nb(date) + ' ' + nb(hour) + ':' + nb(minute) + ':' + nb(second)
        },
        countDownTime2(endTime, maxUnit = "day", startTime) {
            let end = new Date(endTime);
            let start = startTime ? new Date(startTime) : new Date();
            if (start - end > 0) {
                // throw new Error("开始时间不能晚于结束时间")
                return {
                    day: 0,
                    hour: 0,
                    minute: 0,
                    month: 0,
                    second: 0,
                    year: 0
                }
            }
            let aUnitArr = [
                {
                    value: "second",
                    interval: 60,
                    secNum: 1 //该单位有多少秒，计算该单位最大差值用到
                },
                {
                    value: "minute",
                    interval: 60,
                    secNum: 60
                },
                {
                    value: "hour",
                    interval: 24,
                    secNum: 60 * 60
                },
                {
                    value: "day",
                    secNum: 60 * 60 * 24
                },
                {
                    value: "month",
                    interval: 12
                },
                {
                    value: "year",
                },
            ]
            let endList = this.getTimeList(end);
            let startList = this.getTimeList(start);
            const iMaxIndex = aUnitArr.findIndex(item => maxUnit === item.value);
            // 当最大单位为日时分秒时过滤。月份最大单位需根据年份反算所以不能过滤掉年份
            if (iMaxIndex > -1 && iMaxIndex < 4) {
                aUnitArr = aUnitArr.filter((item, index) => iMaxIndex > -1 && index <= iMaxIndex);
            }
            let result = {};
            aUnitArr.forEach((item, index) => {
                if (index === iMaxIndex && iMaxIndex < 4) {
                    result[item.value] = Math.floor((end - start) / item.secNum / 1000);
                    return
                }
                if (endList[index] - startList[index] >= 0) {
                    result[item.value] = endList[index] - startList[index];
                } else {
                    endList[index + 1]--;
                    result[item.value] = item.value === "day" ?
                        this.countDiffDays(start, startList[index], endList[index]) : endList[index] + item.interval - startList[index];
                }
            })
            // 最大单位是月份时特殊处理
            if (maxUnit === "month") {
                result.month += result.year * 12
                delete result.year
            }
            return result;
        },
        getTimeList(t) {
            return [t.getSeconds(), t.getMinutes(), t.getHours(), t.getDate(), t.getMonth() + 1, t.getFullYear()];
        },
        countDiffDays(time, startDay, endDay) {
            let curDate = new Date(time);
            let curMonth = curDate.getMonth();
            /* 这里将时间设置为下个月之前，需要把日期设置小一点，否则极端情况，如果当天日期大于下一个月的总天数，月份会设置为下下个月 */
            curDate.setDate(1)
            curDate.setMonth(curMonth + 1);
            curDate.setDate(0);//日期设置为前一个月的最后一天
            let restDays = curDate.getDate() - startDay;
            return restDays + endDay;
        }
    },
    created() {
        this.getSpecialList()
        this.category()
        this.marketPage()
        this.goodsTag()
        this.advertiseList()
    },
    mounted() {
        this.$nextTick(() => {
            // this.fixedNav()
        })
        // window.addEventListener('scroll', this.fixedNav, false);
    },
    beforeDestroy() { // 在组件生命周期结束的时候销毁。
        window.removeEventListener('scroll', this.fixedNav, false)
        clearInterval(this.timer)

    }
}
</script>
<style scoped>
.home {
    position: relative;
    width: 100%;
    min-height: 100vh;
    /* background-image: url(/img/home-bg.png); */
    background-size: 100% auto;
    padding-bottom: 1.4rem;
    background-repeat: no-repeat;
}

.home-title {
    position: relative;
    z-index: 1;
    width: 2.22rem;
    height: 0.3rem;
    /* margin-top: .24rem; */
    margin-left: .2rem;
    font-size: .28rem;
    color: #fff;
    padding-top: .2rem;
}

.search-box {
    position: relative;
    z-index: 1;
    margin-top: .2rem;
}

.search-box .icon-itt {
    width: .56rem;
    height: .71rem;
    margin-left: .2rem;
}

.search-box .search {
    width: 6.38rem;
    height: .64rem;
    background: #FFFFFF;
    border-radius: .32rem;
    font-size: .28rem;
    font-family: PingFang;
    font-weight: 500;
    color: #BBBBBB;
    margin-left: .16rem;

}

.search-box .search img {
    width: .32rem;
    height: .32rem;
    margin-left: .46rem;
    margin-right: .15rem;
}

.hot-search {
    width: 100%;
    height: .36rem;
    margin-top: .25rem;
}

.hot-search .lb {
    font-size: .22rem;
    font-family: PingFang;
    font-weight: 500;
    color: #FFFFFF;
    margin-left: .2rem;
}

.hot-list {
    flex: 1;
    overflow-x: scroll;
}

.hot-list .item {
    white-space: nowrap;
    background: rgba(255, 255, 255, 0.25);
    border-radius: .18rem;
    font-size: .24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #FFFFFF;
    line-height: .36rem;
    padding: 0 .26rem;
    margin-right: .13rem;
}

.my-swipe {
    width: 7.1rem;
    height: 3.5rem;
    margin-left: .2rem;
    margin-top: .2rem;
}

.my-swipe img {
    width: 7.1rem;
    height: 3.5rem;
    object-fit: cover;
    border-radius: .14rem;
}

.category-box {
    position: relative;
    z-index: 10;
    box-sizing: border-box;
    overflow-x: scroll;
    margin-top: .2rem;
}

.category-box .category-item {
    width: .9rem;
    margin: 0 .2rem;
}

.category-box .category-item img {
    width: .86rem;
    height: .86rem;
    border-radius: .3rem;
}

.category-box .category-item .txt {
    white-space: nowrap;
    font-size: .22rem;
    font-family: PingFang;
    font-weight: 500;
    color: #282828;
    margin-top: .15rem;
}

.indicator-box {
    margin-top: .12rem;
}

.box {
    /* position: relative;
    width: 100%;
    height: .8rem;
    margin-top: .5rem; */
}

.modular-nav-box {
    /* position: relative;
    top: 0;
    left: 0;
    width: 100%; */
    /* height: .8rem;
    overflow-x: auto; */
    /* background-color: rgba(238, 238, 238, 1); */
    z-index: 1000;
}

.modular-nav {
    width: 100%;
    /* position: relative; */
    /* top: 0;
    left: 0; */
    /* width: 100%;
    height: .8rem;
    box-sizing: border-box; */
}

.modular-nav .modular-item {
    width: 100%;
    /* position: relative; */
    white-space: nowrap;
    font-size: .30rem;
    font-family: PingFang;
    font-weight: 800;
    color: #6E6E6E;
    /* margin-right: .65rem; */
    text-align: center;
}

.modular-nav>>>.van-tab--active .active {
    font-size: .36rem;
    font-family: PingFang;
    font-weight: 800;
    color: #282828;
}

.modular-nav>>>.van-tab {
    padding: 0;
    z-index: 2;
}

.modular-nav>>>.van-tab__text {
    /* position: relative; */
    z-index: 3;
}

.modular-nav>>>.van-tabs__nav {
    background-color: transparent;
}

.modular-nav>>>.van-tab--active::before {
    content: '';
    position: absolute;
    bottom: 0rem;
    left: calc(50% - .5rem);
    width: 1rem;
    height: .3rem;
    background-image: url(/img/icon-modular-active.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: 1;
}

.modular-nav>>>.van-tabs__line {
    background-color: transparent !important;
}

.modular-nav>>>.van-tab::after {
    position: absolute;
    content: '';
    top: calc(50% - .16rem);
    width: .02rem;
    height: .32rem;
    background: #CDCCCC;
    right: 0rem;
}

.modular-nav>>>.van-sticky--fixed {
    background-color: #fff;
}

.modular-nav-box .modular-item:nth-last-child(1):after {
    display: none;
}

.ad {
    width: 7.1rem;
    height: 3.15rem;
    margin-left: .2rem;
    margin-top: .2rem;
}

.ad img {
    width: 100%;
    height: 100%;
    background-image: url(/img/ad.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.category-list {
    width: min-content;
}

.category-box::-webkit-scrollbar {
    height: 0;
    display: none;
    /*隐藏滚动条*/
}

.banner-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 6.02rem;
    z-index: 0;
}

.icon-receivingCenter {
    position: fixed;
    right: 0;
    top: 6rem;
    z-index: 100;
    width: 1.12rem;
    height: 1.12rem;
    background-size: 100% 100%;
    background-image: url(/img/icon-receivingCenter.gif);
}
</style>